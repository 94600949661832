import React from 'react';

import { Link } from 'react-router-dom';
import { ReactComponent as feature_placeholder } from '../../svg/misc/feature_placeholder.svg';

import './styles.css';

class Dashboard extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { t } = this.props;

    const features = [
      {
        name: 'feature1',
        desc: 'feature1 description',
        tag: '/feature1',
        icon: feature_placeholder,
      },
      {
        name: 'feature2',
        desc: 'feature2 description',
        tag: '/feature2',
        icon: feature_placeholder,
      },
      {
        name: 'feature3',
        desc: 'feature3 description',
        tag: '/feature3',
        icon: feature_placeholder,
      },
      {
        name: 'feature4',
        desc: 'feature4 description',
        tag: '/feature4',
        icon: feature_placeholder,
      },
      {
        name: 'feature5',
        desc: 'feature5 description',
        tag: '/feature5',
        icon: feature_placeholder,
      },
      {
        name: 'feature6',
        desc: 'feature6 description',
        tag: '/feature6',
        icon: feature_placeholder,
      },
      {
        name: 'feature7',
        desc: 'feature7 description',
        tag: '/feature7',
        icon: feature_placeholder,
      },
      {
        name: 'feature8',
        desc: 'feature8 description',
        tag: '/feature8',
        icon: feature_placeholder,
      },
    ];

    return (
      <div className="view" id="dashboard">
        <div className="row header">
          <div className="wrapper">
            <div className="large-heading">
              <div className="name">exiletech</div>
              <div className="desc">Some text here that describes the page</div>
              <Link className="button" to="/">
                <div className="text">Link to Auth/Character</div>
                <i className="segoe-uniE0AB" />
              </Link>
            </div>
            <div className="features">
              {features.map((h, i) => (
                <div key={i} className="feature">
                  <div className="icon">
                    <h.icon />
                  </div>
                  <div className="text">
                    <div className="name">{h.name}</div>
                    <div className="description">{h.desc}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="additional"></div>
      </div>
    );
  }
}

export default Dashboard;
