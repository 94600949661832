import React from 'react';

import cx from 'classnames';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import * as ls from './utils/localStorage';

import Loading from './views/Loading';
import CharacterSelect from './views/CharacterSelect';
import Header from './components/UI/Header';
import Footer from './components/UI/Footer';

import './App.css';
import { store } from './store';

import Dashboard from './views/Dashboard';

// Print timings of promises to console (and performance logger)
// if we're running in development mode.
async function timed(name, promise) {
  if (process.env.NODE_ENV === 'development') console.time(name);
  const result = await promise;
  if (process.env.NODE_ENV === 'development') console.timeEnd(name);
  return result;
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: {
        code: false,
        detail: false,
      },
    };
  }

  updateViewport = () => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    //console.log('width: ', width, ' height: ', height);
    store.dispatch({ type: 'VIEWPORT_CHANGED', payload: { width, height } });
  };

  async componentDidMount() {
    this.updateViewport();

    window.addEventListener('resize', this.updateViewport);

    try {
      await timed('virtualLoadingTime', this.virtualLoadingTime());
    } catch (e) {
      console.log(e);
    }
  }

  async virtualLoadingTime() {
    this.setState({ status: { code: 'virtualtime' } });

    await sleep(2000);

    this.setState({ status: { code: 'ready' } });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateViewport);
  }

  render() {
    if (this.state.status.code !== 'ready') {
      return (
        <div className={cx('wrapper', 'dark-mode')}>
          <Loading state={this.state.status} />
        </div>
      );
    }

    return (
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/character-select" element={<CharacterSelect />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;
