import React from 'react';

import './styles.css';

class Footer extends React.Component {
  render() {
    return (
      <div id="footer">
        <div className="wrapper">© 2022 | exiletech.org</div>
      </div>
    );
  }
}

export default Footer;
