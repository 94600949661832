import React from 'react';

import gear from '../../../assets/Spinner/gear.png';

import { ReactComponent as Preloader } from '../../../svg/misc/239.svg';

import cx from 'classnames';

import './styles.css';

class Spinner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className={cx('spinner', { mini: this.props.mini })}>
        <Preloader />
      </div>
    );
  }
}

export default Spinner;
