import React from 'react';

import cx from 'classnames';

import './styles.css';

class CharacterSelect extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className={cx('view')} id="character-select">
        <div className="module-header">
          <div className="page-header">
            <div className="name">Select Character</div>
          </div>
        </div>
      </div>
    );
  }
}

export default CharacterSelect;
