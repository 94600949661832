import React from 'react';

import cx from 'classnames';

import packageJSON from '../../../package.json';
import Spinner from '../../components/UI/Spinner';

import { ReactComponent as Saint14 } from '../../svg/misc/Saint14.svg';

import './styles.css';

class Loading extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.loadingStates = {
      error: {
        isError: true,
        status: 'Fatal error',
        displayProperties: {
          name: 'Unknown error',
          description: 'Something went wrong. Fatal error.',
        },
      },
      virtualtime: {
        isError: false,
        status: 'Debug Timer',
        displayProperties: {
          name: 'Debug Timer',
          description: 'Consuming some time to do nothing. Only for Debugging.',
        },
      },
      else: {
        status: 'Perparing the time machine.',
      },
    };
  }

  render() {
    const { state } = this.props;

    if (state.code) {
      const status =
        (this.loadingStates[state.code] &&
          this.loadingStates[state.code].status) ||
        this.loadingStates.else.status;
      const isError =
        this.loadingStates[state.code] &&
        (this.loadingStates[state.code].isError ||
          this.loadingStates[state.code].shh);

      return (
        <div className="view" id="loading">
          <div className="bg">
            <div className="container">
              <Saint14 />
            </div>
          </div>
          <div className="logo">
            <div className="device">
              <p>LOGO</p>
            </div>
          </div>
          <div className="text">
            <div className="version">exiletech {packageJSON.version} beta</div>
            <div className={cx('status', { error: isError })}>
              {!isError ? <Spinner mini dark /> : null}
              <div>{status}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Loading;
