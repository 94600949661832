import React from 'react';
import { Link } from 'react-router-dom';

import cx from 'classnames';

import './styles.css';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navOpen: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    let profileElement = null;
    if (true) {
      profileElement = <div className="profile">Profile</div>;
    }

    return (
      <div
        id="header"
        className={cx({
          'profile-header': profileElement,
          navOpen: this.state.navOpen,
        })}
      >
        <div className="exiletech">
          <div className="wrapper">
            <div className="logo">logo</div>
            <div className="links">
              <li>
                <Link to="character-select">character-select</Link>
              </li>
              <li>
                <Link to="/">home</Link>
              </li>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
