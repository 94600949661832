import { configureStore } from '@reduxjs/toolkit';

import auth from './reducers/auth';
import theme from './reducers/theme';

export const store = configureStore({
  reducer: {
    auth,
    theme,
  },
});
