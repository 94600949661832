import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store";

import packageJSON from "../package.json";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

class AppEntry extends React.Component {
  constructor(props) {
    super();

    this.state = {
      updateAvailable: false,
    };

    console.log(
      `%c exiletech ${packageJSON.version}`,
      "font-family: sans-serif; font-size: 24px;"
    );
  }

  render() {
    return (
      <Provider store={store}>
        <App {...this.state} />
      </Provider>
    );
  }
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AppEntry />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
